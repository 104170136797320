import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(({
  root: {}
}));

const UserCard = ({ className, userName, handleCategoryChange }) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, className)}
    >
      <Box>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              md={6}
              sm={6}
              xs={12}
              item={true}
            >
              <Box
                alignItems="left"
                display="flex"
                flexDirection="column"
              >
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h3"
                  >
                    Assign tyres to {userName}
                </Typography>

              </Box>
              
            </Grid>
            <Grid
              className={classes.item}
              md={6}
              sm={6}
              xs={12}
              item={true}
            >
              <Box
                alignItems="right"
                display="flex"
                flexDirection="column"
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleCategoryChange}
                >
                  Assign tyres and categories to user
                </Button>
              </Box>
              
            </Grid>


          </Grid>
          
        </CardContent>
      </Card>
    </Box>
    </div>
  );
};

UserCard.propTypes = {
  className: PropTypes.string,
  userName: PropTypes.string.isRequired
};

export default UserCard;
