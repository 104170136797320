import { Button, TextField, Box } from '@material-ui/core';
import React, { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import appConfig from "../../../config";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { reject } from 'lodash';

import Pool from '../../../UserPool';

var cognitoUser = Pool.getCurrentUser();


const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();

    const handleOldPasswordChange = (event) => {
        setOldPassword(event.target.value);
    }

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    }

    const getSession = async () => {
        await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            console.log('cognitoUser', user)
            if (user) {
                user.getSession((error, session) => {
                    if (error){
                        reject();
                    } else{
                        resolve(session);
                        console.log("session :", session)
                        console.log('cognitoUser', user)
                    }
                })
            } else{
                reject();
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // console.log('user2 :', cognitoUser);
        if (cognitoUser) {
            cognitoUser.getSession((error, session) => {
                if (error){
                    reject();
                } else{
                    // resolve(session);
                    cognitoUser.changePassword(oldPassword, newPassword, function(err, result) {
                        if (err) {
                            alert(err.message || JSON.stringify(err));
                            return;
                        }
                        console.log('call result: ' + result);
                    });
                }
            })
        } else{
            reject();
        }

        // console.log("cognitoUser :", cognitoUser);
        // getSession();
    }

    const logCurrentUser = () => {
        // var cognitoUser = Pool.getCurrentUser();
        console.log('user2 :', cognitoUser);
    }
    return(
        <div>
            <Button
                onClick={logCurrentUser}
            >
                Current user
            </Button>
<form onSubmit={handleSubmit}>
            <Box>
                <TextField
                    name="oldPassword"
                    placeholder="old password"
                    onChange={handleOldPasswordChange}
                />
            </Box>
            <Box>
                <TextField
                    name="newPassword"
                    placeholder="new password"
                    onChange={handleNewPasswordChange}
                />
            </Box>
            <Box>
                <Button
                    type="submit"
                >
                    Update
                </Button>
            </Box>
        </form>
        </div>
        
    );
}

export default ChangePassword;