import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Container,
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { getUsers, deleteUser, resetUserPassword } from 'src/server/UserAPI';
import UserListContext from 'src/contexts/UserListContext';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SkeletonLoader = () => {
  let skeletonArray = new Array(10).fill({})
  return(
    <Box mt={4} mr={4} ml={4}>
      <Skeleton variant='rect' animation='wave' height={165}/>
      {skeletonArray.map((item, itr) => (
        <Skeleton key={itr} variant='text' animation='wave' height={70} style={{ marginBottom: -20 }} />
      ))}
    </Box>
  )
}

const CustomerListView = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true)
  const [paginationLimit, setPaginationLimit] = useState(10)

  const { usersList, setUsersList } = useContext(UserListContext)

  const [currentResultToken, setCurrentResultToken] = useState(null)
  const [nextResultToken, setNextResultToken] = useState()
  const [previousTokensList, setPreviousTokensList] = useState([])
  const [searchText, setSearchText] = useState('')
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [isOpenPasswordInfoDialog, setIsOpenPasswordInfoDialog] = useState(false)
  const [isOpenPasswordConfirmationDialog, setIsOpenPasswordConfirmationDialog] = useState(false)
  const [password, setPassword] = useState('')
  const [userDetails, setUserDetails] = useState(null)
  const [isDeletingUser, setIsDeletingUser] = useState(false)
  const [isResendingUserPassword, setIsResendingUserPassword] = useState(false)

  useEffect(() => {
    handleGetUsers()
  }, [searchText, currentResultToken, paginationLimit])

  const handleGetUsers = () => {
    getUsers(paginationLimit, searchText, currentResultToken)
      .then(response => {
        setNextResultToken(response.PaginationToken)
        setUsersList(response.Users)
        setIsLoading(false)
      })
      .catch(error => enqueueSnackbar(error.toString(), { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }))
  }

  const handleSeachInput = (event) => {
    setSearchText(event.target.value)
  }

  const TablePaginationActions = () => {
    return (
      <div style={{display: 'flex', flexShrink: 0, marginLeft: 8}}>
        <IconButton onClick={handleBackButtonClick} disabled={previousTokensList.length === 0}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton onClick={handleNextButtonClick} disabled={!nextResultToken}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
    );
  }

  const handleBackButtonClick = () => {
    const previousTokensListCopy = [...previousTokensList]
    const tokenForPreviousFetch = previousTokensListCopy.pop()
    setPreviousTokensList(previousTokensListCopy)
    setCurrentResultToken(tokenForPreviousFetch)
  };

  const handleNextButtonClick = () => {
    setPreviousTokensList((previousTokensList) => [...previousTokensList, currentResultToken])
    setCurrentResultToken(nextResultToken)
  };

  const handlePaginationLimitChange = (event) => {
    setCurrentResultToken(null)
    setPreviousTokensList([])
    setPaginationLimit(event.target.value);
  }

  const handleDeleteUserClick = (userData) => {
    setUserDetails(userData)
    setOpenDeleteDialog(true)
  }

  const resendPassword = () => {
    setIsResendingUserPassword(true)
    
    const data = {
      ...userDetails,
      Attributes: userDetails.Attributes.reduce((acc, { Name, Value }) => ({...acc, [Name]: Value }), {}),
    }
    resetUserPassword(data.Attributes.name, data.Attributes.email)
    .then((res) => {
      const password = res['password']
      setPassword(password)
      setIsOpenPasswordInfoDialog(true)
      setIsResendingUserPassword(false)
      setIsOpenPasswordConfirmationDialog(false)
    })
      .catch(error => {
        setIsResendingUserPassword(false)
        setIsOpenPasswordConfirmationDialog(false)
        return enqueueSnackbar(error.toString(), { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })
      })
  }
  const handleResendPassword = (userData) => {
    console.log(userData)
    setIsOpenPasswordConfirmationDialog(true)
    setUserDetails(userData)
  }
  
  const onDeleteDialogClose = () => {
    setOpenDeleteDialog(false)
    setIsDeletingUser(false)
    setUserDetails(null)
  }

  const handleDeleteUser = () => {
    setIsDeletingUser(true)
    const userName =  userDetails.Username

    deleteUser(userName)
    .then(response => {
      onDeleteDialogClose()
      const username = response.userdata.user_name
      setIsLoading(true)
      handleGetUsers()
      enqueueSnackbar(`User ${username} Has Been Successfully Deleted`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal:  'center' } })
    })
      .catch(error => {
        onDeleteDialogClose()
        return enqueueSnackbar(error.toString(), { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })
      })
  }

  if(isLoading) {
    return (
      <SkeletonLoader/>
    )
  }

  return (
    <Page
      className={classes.root}
      title="Customers"
    >
      <Container maxWidth={false}>
        <Toolbar searchText={searchText} setSearchText={handleSeachInput} />
        <Box mt={3}>
          <Results
          customers={usersList}
          paginationLimit={paginationLimit}
          handlePaginationLimitChange={handlePaginationLimitChange}
          tablePaginationActions={TablePaginationActions}
          handleDeleteUserClick={handleDeleteUserClick}
          handleResetUserClick={handleResendPassword} />
        </Box>
        {openDeleteDialog && <Dialog open={openDeleteDialog} onClose={onDeleteDialogClose}>
          <DialogTitle>
            <Typography variant='h4'>Delete User</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{color: '#000'}}>
              Are you sure want to delete user <span style={{fontWeight: '800'}}>{userDetails.Attributes[0].Value}</span> from user list?
            </DialogContentText>
            {isDeletingUser && <CircularProgress color='primary' size={30}/>}
          </DialogContent>
          <DialogActions>
            <Button disabled={isDeletingUser} onClick={onDeleteDialogClose} style={{color: isDeletingUser ? 'gray' : '#fc5858'}}>Cancel</Button>
            <Button disabled={isDeletingUser} onClick={handleDeleteUser} color='primary'>Delete</Button>
          </DialogActions>
        </Dialog>}
        {isOpenPasswordConfirmationDialog && <Dialog open={isOpenPasswordConfirmationDialog} onClose={() => setIsOpenPasswordConfirmationDialog(false)}>
          <DialogTitle>
            <Typography variant='h4'>Resend User Password</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: '#000' }}>
              Are you sure want to resend a new password to user <span style={{ fontWeight: '800' }}>{userDetails.Attributes[0].Value}</span>? <br></br>
              An email notification will be sent to the customer with login details.
            </DialogContentText>
            {isResendingUserPassword && <CircularProgress color='primary' size={30} />}
          </DialogContent>
          <DialogActions>
            <Button disabled={isResendingUserPassword} onClick={() => setIsOpenPasswordConfirmationDialog(false)} style={{ color: isResendingUserPassword ? 'gray' : '#fc5858' }}>Cancel</Button>
            <Button disabled={isResendingUserPassword} onClick={resendPassword} color='primary'>Resend Password</Button>
          </DialogActions>
        </Dialog>}
        {isOpenPasswordInfoDialog && <Dialog
          open={isOpenPasswordInfoDialog}
          onClose={() => setIsOpenPasswordInfoDialog(false)}>
          <DialogTitle id="alert-dialog-title">
            <Typography variant='h4'>Resent User Password Successfully!</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: '#000' }}>
              <br></br>
              An email notification has been sent to the customer with the new password info.<br></br>
              PASSWORD: <span style={{ fontWeight: '800' }}>{password}</span>
              <br></br>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOpenPasswordInfoDialog(false)}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        }
      </Container>
    </Page>
  );
};

export default CustomerListView;
