import { v4 as uuid } from 'uuid';

export default {
  id: uuid(),
  createdAt: '27/03/2019',
  description: 'Category 1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
  media: '/static/images/products/agriculture.png',
  title: 'Tire ABC',
  totalDownloads: '594'
};
