import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  ButtonGroup,
  Grid,
  Typography,
  TextField,
  makeStyles
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ProductCardForm = ({ className, product, ...rest }) => {
  const classes = useStyles();
  let navigate = useNavigate();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        subheader="Update Id"
        title="ID"
      />
      <Divider />
      <CardContent>
        <TextField
          fullWidth
          label="Product Id"
          margin="normal"
          name="product-id"
          type="text"
          variant="outlined"
        /> 
      </CardContent>
      <Divider />
      <Box display='flex' justifyContent='space-between' p={2}>
        <Box>
            <Button
              variant="contained"
              style={{backgroundColor: '#fc5858', color: '#fff'}}
              onClick={() => navigate('/app/products', { replace: true })}>
                Cancel
            </Button>
        </Box>
        <Box>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button>Preview</Button>
            <Button>Update</Button>
          </ButtonGroup>
        </Box>
      </Box>
    </Card>
  );
};

ProductCardForm.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCardForm;
