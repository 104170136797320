import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  Grid,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Collapse from '@material-ui/core/Collapse';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  toggleButton: {
    "&.MuiToggleButton-root": {
      backgroundColor: "#00509d",
      color: '#fff',
      fontSize: '10px',
      [theme.breakpoints.up('lsm')]: {
        fontSize: '10px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
      },
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main
    },
    "&.MuiToggleButtonGroup-grouped": {
      borderLeftColor: '#d9d9d9'
    },
  }
}));

const Toolbar = ({ className, tyresAndCategories, selectedCategoryKey, setSelectedCategory, selectedSubCategoryKey, setSelectedSubCategoryId, typedSearchText, setTypedSearchText, ...rest }) => {
  const classes = useStyles();
  const handleCategoryChange = (event, category) => {
    setSelectedCategory(category)
    setSelectedSubCategoryId("")
  }

  const handleSubCategoryChange = (event, subCategoryKey) => {
    setSelectedSubCategoryId(subCategoryKey)
  }

  const handleSearch = (event) => {
    setTypedSearchText(event.target.value)
  }

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search tyre"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <ToggleButtonGroup exclusive value={selectedCategoryKey} onChange={handleCategoryChange}>
                  <ToggleButton value='' className={classes.toggleButton}>All</ToggleButton>
                  {Object.keys(tyresAndCategories).map((categoryKey, index) => (
                    <ToggleButton key={index} value={categoryKey} className={classes.toggleButton}>{categoryKey}</ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <Box mt={1}>
              <Collapse in={selectedCategoryKey !== ''}>
                {selectedCategoryKey &&
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <ToggleButtonGroup size='small' exclusive value={selectedSubCategoryKey} onChange={handleSubCategoryChange}>
                        <ToggleButton value='' className={classes.toggleButton}>All</ToggleButton>
                        {Object.keys(tyresAndCategories[selectedCategoryKey].machineries).map((subCategoryKey, index) => (
                          <ToggleButton key={index} value={subCategoryKey} className={classes.toggleButton}>{subCategoryKey}</ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                }
              </Collapse>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
