import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Container,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { useParams } from "react-router-dom";
import Page from 'src/components/Page';
import UserCard from './UserCard';
import CategoriesCard from './CategoriesCard';
import SubCategoriesCard from './SubCategoriesCard';
import AlltiresCard from './AlltiresCard';
import { useSnackbar } from 'notistack';
import { updateUserAttributes, getUser, getTyres } from 'src/server/UserAPI';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const AssignTyre = ({ className, match, ...rest }) => {
  let { userName } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [tyresAndCategories, setTyresAndCategories] = useState({});
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchUser();
  }, []);

  const getTyresCount = () => {
    let tyresCount = 0
    Object.keys(tyresAndCategories).forEach(categoryKey => {
      Object.keys(tyresAndCategories[categoryKey].machineries).forEach(subCategoryKey => {
        tyresCount += Object.keys(tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres).length
      });
    });
    return tyresCount
  }

  const tyresCount = useMemo(getTyresCount, [tyresAndCategories]);

  const setTyres = (categoryKey, subCategoryKey, isSelected) => {
    Object.keys(tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres).forEach(tyrekey => {
      tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres[tyrekey]["isSelected"] = isSelected;
    });
  };

  const handleCategoryChange = (event) => {
    tyresAndCategories[event.target.name].isSelected = event.target.checked;
    Object.keys(tyresAndCategories[event.target.name].machineries).forEach(subCategoryKey => {
      tyresAndCategories[event.target.name].machineries[subCategoryKey].isSelected = event.target.checked;
      setTyres(event.target.name, subCategoryKey, event.target.checked);
    });
    let newTyresAndCategories = Object.assign({}, tyresAndCategories);
    setTyresAndCategories(newTyresAndCategories);
  };

  const handleSubCategoryChange = (event, categoryKey) => {
    setTyres(categoryKey, event.target.name, event.target.checked);
    tyresAndCategories[categoryKey].machineries[event.target.name].isSelected = event.target.checked;
    let newTyresAndCategories = Object.assign({}, tyresAndCategories);
    setTyresAndCategories(newTyresAndCategories);
  };

  const handleTyreChange = (event, subCategoryKey, categoryKey) => {
    tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres[event.target.name].isSelected = event.target.checked;
    let newTyresAndCategories = Object.assign({}, tyresAndCategories);
    setTyresAndCategories(newTyresAndCategories);
  };

  const tyreMapToCategory = (categories, tyres) => {
    categories = categories.reduce((category, { name, ...rest }) => (category[name] = rest, category), {})
    Object.keys(categories).forEach(categoryKey => {
      categories[categoryKey].machineries = categories[categoryKey].machineries.reduce((machine, { name, ...rest }) => (machine[name] = rest, machine), {})
      Object.keys(categories[categoryKey].machineries).forEach(machinaryKey => {
        const filtered_tyre = tyres.filter(tyre => (tyre.purpose_id == categories[categoryKey].id && tyre.machinery_id == categories[categoryKey].machineries[machinaryKey].id))
        categories[categoryKey].machineries[machinaryKey]['tyres'] = filtered_tyre.reduce((tyre, { name, ...rest }) => (tyre[name] = rest, tyre), {})
      })
    })
    return categories
  }

  const mergeUserSelectedTires = (selectedTyresAndCategories, tyresAndCategories) => {
    Object.keys(tyresAndCategories).map(categoryKey => {
      if (selectedTyresAndCategories.CategoryIds.includes(tyresAndCategories[categoryKey].id)) {
        tyresAndCategories[categoryKey].isSelected = true;
      }
      Object.keys(tyresAndCategories[categoryKey].machineries).map(subCategoryKey => {
        if (selectedTyresAndCategories.MachinaryIds.includes(tyresAndCategories[categoryKey].machineries[subCategoryKey].id)) {
          tyresAndCategories[categoryKey].machineries[subCategoryKey].isSelected = true;
        }
        Object.keys(tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres).map(tyreKey => {
          if (selectedTyresAndCategories.TyreIds.includes(tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres[tyreKey].id)) {
            tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres[tyreKey].isSelected = true;
          }
        });
      });
    });
  };

  const getTyresAndCategories = (selectedTyresAndCategories) => {
    getTyres()
      .then(response => {
        const tyresAndCategories = tyreMapToCategory(response.categories, response.tires)
        mergeUserSelectedTires(selectedTyresAndCategories, tyresAndCategories);
        setTyresAndCategories(tyresAndCategories)
        setIsLoading(false)
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }));
  }

  const handleAssignTyreAndCategoriesToUser = () => {
    const selectedCategories = []
    Object.keys(tyresAndCategories).forEach(categoryKey => {
      if (tyresAndCategories[categoryKey].isSelected) {
        selectedCategories.push(tyresAndCategories[categoryKey].id)
      }
    })
    
    const selectedMachineries = []
    Object.keys(tyresAndCategories).forEach(categoryKey => {
      Object.keys(tyresAndCategories[categoryKey].machineries).forEach(subCategoryKey => {
        if (tyresAndCategories[categoryKey].machineries[subCategoryKey].isSelected) {
          selectedMachineries.push(tyresAndCategories[categoryKey].machineries[subCategoryKey].id)
        }
      })
    })

    const selectedTyres = []
    Object.keys(tyresAndCategories).forEach(categoryKey => {
      Object.keys(tyresAndCategories[categoryKey].machineries).forEach(subCategoryKey => {
        Object.keys(tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres).forEach(tyreKey => {
          if (tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres[tyreKey].isSelected) {
            selectedTyres.push(tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres[tyreKey].id)
          }
        })
      })
    })
    
    const selectedTyresandCategoriesAttribute = { "CategoryIds": selectedCategories, "MachinaryIds": selectedMachineries, "TyreIds": selectedTyres }
    updateUserAttributes(userName, selectedTyresandCategoriesAttribute)
      .then(() => {
        enqueueSnackbar('Successfully Assigned Selected Tires And Categories To The User ' +userName, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' } })
        console.log("Successfully User Attribute has been updated")
      })
      .catch(error => enqueueSnackbar(error.toString(), { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }))
  };

  const fetchUser = () => {
    getUser(userName)
      .then(res => {
        let selectedTyresAndCategories
        if (res.response.UserAttributes.find(attribute => attribute.Name == "custom:tire") && (res.response.UserAttributes.find(attribute => attribute.Name == "custom:tire").Value !== "null")) {
          const response = res.response.UserAttributes.find(attribute => attribute.Name == "custom:tire").Value
          selectedTyresAndCategories = JSON.parse(response)
        }
        else selectedTyresAndCategories = { "CategoryIds": [], "MachinaryIds": [], "TyreIds": [] }
        getTyresAndCategories(selectedTyresAndCategories)
      })
      .catch(error => enqueueSnackbar(error.toString(), { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }))
  };

  return (
    <Page
      className={classes.root}
      title="Settings"
    >
      <Container maxWidth="lg">
        <Box>
          <form>
            {(!isLoading) &&
              <UserCard userName={userName} handleCategoryChange={handleAssignTyreAndCategoriesToUser} />
            }
            {(isLoading) && (
              <Box
                display="flex"
                justifyContent="center"
                pt={2}
              >
                <CircularProgress className={classes.loader} />
              </Box>
            )}
            {(!isLoading) &&
              <CategoriesCard tyresAndCategories={tyresAndCategories} handleCategoryChange={handleCategoryChange} />
            }
            {(!isLoading) &&
              <SubCategoriesCard tyresAndCategories={tyresAndCategories} handleSubCategoryChange={handleSubCategoryChange} />
            }
            {(!isLoading) &&
              < AlltiresCard tyresCount={tyresCount} tyresAndCategories={tyresAndCategories} handleTyreChange={handleTyreChange} />
            }
          </form>
        </Box>
      </Container>
    </Page>
  );
};

export default AssignTyre;
