import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Grid,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, searchText, setSearchText, ...rest }) => {
  const classes = useStyles();

  const addUserLink = "adduser";

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box>
        <Card>
          <CardContent>
            <Grid
              alignItems='center'
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={8}
              >
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  value={searchText}
                  onChange={event => setSearchText(event)}
                  placeholder="Search user"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={4}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Link to={addUserLink}>
                    <Button
                      color="primary"
                      variant="contained"
                    >
                      Add user
                    </Button>
                  </Link>
                  
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
