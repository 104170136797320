import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import CustomerListView from 'src/views/customer/CustomerListView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SingleProductView from 'src/views/product/SingleProductView';
import SettingsView from 'src/views/settings/SettingsView';

import AssignTyreView from 'src/views/customer/AssignTyreView';
import AddUserView from 'src/views/customer/AddUserView';

import Login from 'src/views/auth/Login';
import ChangePassword from 'src/views/settings/SettingsView/ChangePassword';

  const routes = (isAutheticated) =>[
  {
    path: 'app',
    element: isAutheticated ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'customers', element: <CustomerListView /> },
      { path: 'customers/assignTyre/:userName', element: <AssignTyreView /> },
      { path: 'customers/addUser', element: <AddUserView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'products/singleProduct', element: <SingleProductView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/customers" /> },
      { path: '*', element: <Navigate to="/404" /> },
      
    ]
  },
  {
  path: '/account2',
  element: <MainLayout/>,
  children: [
    { path: 'login2', element: <Login /> },
    { path: 'changePassword', element: <ChangePassword />}
  ]
}

];

export default routes;
