import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(({
  root: {}
}));

const SubCategoriesCardItem = ({ categoryKey, tyresAndCategories, handleSubCategoryChange }) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.item}
      style={{marginBottom: 10}}
      item
      md={12}
      sm={12}
      xs={12}
    >
      <Typography
        color="textPrimary"
        gutterBottom
        variant="h6"
      >
        {categoryKey}
      </Typography>
      {Object.keys(tyresAndCategories[categoryKey].machineries).map(subCategoryKey => {
        return (
          <Grid
            className={classes.item}
            item
            md={12}
            sm={12}
            xs={12}
            key={subCategoryKey}
          >
            
            <FormControlLabel
              control={<Checkbox
                checked={tyresAndCategories[categoryKey].machineries[subCategoryKey].isSelected || false}
                onChange={(e) => handleSubCategoryChange(e, categoryKey)}
                name={subCategoryKey}
                data-cat={categoryKey} />}
              label={subCategoryKey}
              style={{ marginLeft: 5 }}
          />
          </Grid>  
        );
      })}
    </Grid>
  );
};

SubCategoriesCardItem.propTypes = {
  categoryKey: PropTypes.string.isRequired,
  tyresAndCategories: PropTypes.object.isRequired
};

export default SubCategoriesCardItem;
