import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, createUser, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const phoneRegExp = /^^\+[0-9\+]{9,15}?$/

  return (
    <Formik
      initialValues={{ name: '', email: '', phone_number: '' }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Please Add The Name'),
        email: Yup.string().email('Please Add A Valid E-mail Address').required('Please Add The E-mail Address'),
        phone_number: Yup.string().matches(phoneRegExp, 'Please Add The Phone Number In +94xxxxxxxxx Format')
      })}
      onSubmit={(values, actions) => {
        createUser(values, actions)
      }}>
      {({ errors, handleSubmit, handleChange, isSubmitting, values, touched }) => (
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className={clsx(classes.root, className)}
        {...rest}>
        <Card>
          <CardHeader
            title="Create user"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"/>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"/>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={Boolean(touched.phone_number && errors.phone_number)}
                  helperText={touched.phone_number && errors.phone_number}
                  label="Phone Number"
                  name="phone_number"
                  onChange={handleChange}
                  value={values.phone_number}
                  variant="outlined"/>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            p={2}>
            <Button
              style={{backgroundColor: '#fc5858', color: '#fff'}}
              onClick={() => navigate('/app/customers', { replace: true })}
              variant="contained"
              disabled={isSubmitting}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              type="submit">
              Create user
            </Button>
          </Box>
        </Card>
      </form>)}
    </Formik>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
