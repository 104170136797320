import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ProductCard from './ProductCard';
import ProductCardForm from './ProductCardForm';
import data from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  },
  productCard: {
    height: '100%'
  }
}));

const SingleProduct = () => {
  const classes = useStyles();
  const [product] = useState(data);

  return (
    <Page
      className={classes.root}
      title="Product"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              key={product.id}
              lg={4}
              md={6}
              xs={12}
            >
              <ProductCard
                className={classes.productCard}
                product={product}
              />
            </Grid>
            <Grid
              item
              key={product.id}
              lg={8}
              md={6}
              xs={12}
            >
              <ProductCardForm
                className={classes.productCard}
                product={product}
              />
            </Grid>

          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default SingleProduct;
