import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import Pool from '../src/UserPool';
import AuthContext from './contexts/AuthContext';
import TyreAndCategoryContext from './contexts/TyreAndCategoryContext';
import UserListContext from './contexts/UserListContext';
import { SnackbarProvider } from 'notistack';

const App = () => {
  const[isAutheticated, setisAutheticated] = useState(undefined);
  const routing = useRoutes(routes(isAutheticated));
  const [tyresAndCategories, setTyresAndCategories] = useState(null)
  const [usersList, setUsersList] = useState([])

  const toggleIsAuthenticated = () => {
      setisAutheticated(!isAutheticated);
  }
  
  const getSession = async () => {
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((error, session) => {
          if (error) {
            reject();
          } else {
            if (session.isValid()){
              setisAutheticated(true);
            } else {
              setisAutheticated(false);
            }
          }
        })
      } else {
        setisAutheticated(false);
        resolve()
        
      }
    })
  }

  useEffect(() => {
    getSession();
  }, [isAutheticated]);

  if(isAutheticated == undefined) {
    return 'initializing'
  }

  return (
    <ThemeProvider theme={theme}>
        <GlobalStyles />
      <AuthContext.Provider value={{ isAutheticated, toggleIsAuthenticated}}>
        <TyreAndCategoryContext.Provider value={{tyresAndCategories, setTyresAndCategories}}>
          <UserListContext.Provider value={{usersList, setUsersList}}>
            <SnackbarProvider maxSnack={1}>
            {routing}
          </SnackbarProvider>
          </UserListContext.Provider>
        </TyreAndCategoryContext.Provider>
      </AuthContext.Provider>
    </ThemeProvider>
  );
};

export default App;
