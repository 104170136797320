import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(({
  root: {}
}));

const CategoriesCard = ({ tyresAndCategories, handleCategoryChange }) => {
  const classes = useStyles();
  return (
    <Box mt={3}>
      <Card>
        <CardHeader
          title="Categories"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            {Object.keys(tyresAndCategories).map(categoryKey => {
              return (
                <Grid
                  key={categoryKey}
                  className={classes.item}
                  item
                  md={4}
                  sm={6}
                  xs={12}
                >
                  <FormControlLabel
                    control={<Checkbox
                      checked={tyresAndCategories[categoryKey].isSelected || false}
                      onChange={handleCategoryChange}
                      name={categoryKey} />}
                    label={categoryKey}
                  />
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

CategoriesCard.propTypes = {
  tyresAndCategories: PropTypes.object.isRequired
};

export default CategoriesCard;
