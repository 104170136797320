import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import Skeleton from '@material-ui/lab/Skeleton';
import { getTyres } from 'src/server/UserAPI';
import TyreAndCategoryContext from 'src/contexts/TyreAndCategoryContext';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  }
}));

const SkeletonLoader = () => {
  let skeletonArray = new Array(10).fill({})
  return (
    <Box mt={4} mr={4} ml={4}>
      <Skeleton variant='rect' animation='wave' height={165} />
      {skeletonArray.map((item, itr) => (
        <Skeleton key={itr} variant='text' animation='wave' height={70} style={{ marginBottom: -20 }} />
      ))}
    </Box>
  )
}

const ProductListView = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true)
  const [searchText, setsearchText] = useState('')
  const [categoryKey, setCategoryKey] = useState('')
  const [subCategoryKey, setSubCategoryKey] = useState('')

  const { tyresAndCategories, setTyresAndCategories } = useContext(TyreAndCategoryContext)

  useEffect(() => {
    getTyresAndCategories()
  }, [])

  const tyreMapToCategory = (categories, tyres) => {
    categories = categories.reduce((category, { name, ...rest }) => (category[name] = rest, category), {})
    Object.keys(categories).forEach(categoryKey => {
      categories[categoryKey].machineries = categories[categoryKey].machineries.reduce((machine, { name, ...rest }) => (machine[name] = rest, machine), {})
      Object.keys(categories[categoryKey].machineries).forEach(machinaryKey => {
        const filtered_tyre = tyres.filter(tyre => (tyre.purpose_id == categories[categoryKey].id && tyre.machinery_id == categories[categoryKey].machineries[machinaryKey].id))
        categories[categoryKey].machineries[machinaryKey]['tyres'] = filtered_tyre.reduce((tyre, { id, ...rest }) => (tyre[id] = rest, tyre), {})
      })
    })
    return categories
  }

  const getTyresAndCategories = () => {
    getTyres()
      .then((response) => {
        let mappedTyresAndCategories = tyreMapToCategory(response.categories, response.tires)
        setTyresAndCategories(mappedTyresAndCategories)
        setIsLoading(false)
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }));
  }

  if (isLoading) {
    return (
      <SkeletonLoader />
    )
  }

  let categories = {};
  if (!categoryKey) {
    Object.keys(tyresAndCategories).forEach(categoryKey => {
      categories[categoryKey] = tyresAndCategories[categoryKey]
    });
  }
  else categories[categoryKey] = tyresAndCategories[categoryKey];

  let filteredTyresByCategory = {}
  Object.keys(categories).map(categoryKey => {
    Object.keys(categories[categoryKey].machineries).map(subCategoryKey => {
      filteredTyresByCategory = { ...filteredTyresByCategory, ...categories[categoryKey].machineries[subCategoryKey].tyres }
    })
  })
  
  let filteredTyresBySubCategory = {}
  if (subCategoryKey) {
    Object.keys(filteredTyresByCategory).forEach(tyreKey => {
      if (filteredTyresByCategory[tyreKey].machinery_id == tyresAndCategories[categoryKey].machineries[subCategoryKey].id) {
        filteredTyresBySubCategory[tyreKey] = filteredTyresByCategory[tyreKey]
      }
    })
  }
  else filteredTyresBySubCategory = filteredTyresByCategory

  let filteredTyresBySearchText = {}
  if (searchText) {
      let filteredArray = Object.values(filteredTyresBySubCategory).filter((tyre) => {
        return tyre.name.includes(searchText.toUpperCase());
      });
      Object.keys(filteredArray).forEach((tyreKey) => {
        filteredTyresBySearchText[tyreKey] = filteredArray[tyreKey];
      });
  }
  else filteredTyresBySearchText = filteredTyresBySubCategory

  return (
    <Page
      className={classes.root}
      title="Tyres"
    >
      <Container maxWidth={false}>
        <Toolbar
          tyresAndCategories={tyresAndCategories}
          selectedCategoryKey={categoryKey}
          setSelectedCategory={setCategoryKey}
          selectedSubCategoryKey={subCategoryKey}
          setSelectedSubCategoryId={setSubCategoryKey}
          typedSearchText={searchText}
          setTypedSearchText={setsearchText} />
        <Box mt={3}>
          <Results tyres={filteredTyresBySearchText} tyresAndCategories={tyresAndCategories} />
        </Box>
      </Container>
    </Page>
  );
};

export default ProductListView;
