import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Button
} from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiLogoutVariant } from '@mdi/js';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import Pool from '../../UserPool';
import AuthContext from './../../contexts/AuthContext';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  button: {
    color: 'white'
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const auth = useContext(AuthContext);

  const logout = () => {
    const cognitoUser = Pool.getCurrentUser();
    if (cognitoUser !== null) {
      cognitoUser.signOut();
    }
    auth.toggleIsAuthenticated(false);
  };

  return (
    <AppBar className={clsx(classes.button, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Box>
          <Button onClick={logout} className={clsx(classes.button, className)}>
            Log out
          </Button>
        </Box>
        <Hidden mdDown>
          <IconButton color="inherit" onClick={logout}>
            <div className={classes.sectionDesktop}>
              <IconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                color="inherit"
                onClick={logout}
              >
                <Icon
                  path={mdiLogoutVariant}
                  title="Log out"
                  size={1.3}
                  horizontal
                  vertical
                />
              </IconButton>
            </div>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
