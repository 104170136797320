import { Button, TextField, Box } from '@material-ui/core';
import React, { useState } from 'react';
import * as AWS from 'aws-sdk/global';
import appConfig from "../../config";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import UserPool from '../../UserPool';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [token, setToken] = useState();

    const emailChange = (event) => {
        setEmail(event.target.value);
    }

    const passwordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var authenticationData = {
            Username: email,
            Password: password,
        };
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData
        );

        const poolData = {
            UserPoolId: appConfig.UserPoolId, 
            ClientId: appConfig.ClientId, 
        };

        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        var userData = {
            Username: email,
            Pool: userPool,
        };
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                var accessToken = result.getAccessToken().getJwtToken();

                //POTENTIAL: Region needs to be set if not already set previously elsewhere.
                AWS.config.region = appConfig.region;

                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: appConfig.IdentityPoolId, // your identity pool id here
                    // IdentityPoolId: appConfig.IdentityPoolId, // your identity pool id here
                    Logins: {
                        // Change the key below according to the specific region your user pool is in.
                        [appConfig.IdentityPoolId]: result
                            .getIdToken()
                            .getJwtToken(),
                    },
                });

                //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
                AWS.config.credentials.refresh(error => {
                    if (error) {
                        console.error(error);
                    } else {
                        // Instantiate aws sdk service objects now that the credentials have been updated.
                        // example: var s3 = new AWS.S3();
                        console.log('Successfully logged!');
                    }
                });
            },

            // newPasswordRequired: function(userAttributes, requiredAttributes) {
            //     // User was signed up by an admin and must provide new
            //     // password and required attributes, if any, to complete
            //     // authentication.

            //     // the api doesn't accept this field back
            //     delete userAttributes.email_verified;

            //     // unsure about this field, but I don't send this back
            //     delete userAttributes.phone_number_verified;

            //     // Get these details and call
            //     cognitoUser.completeNewPasswordChallenge('1996#MiN', userAttributes, this);
            // },

            // onFailure: function (error) {
            //     alert(error.message || JSON.stringify(error));
            // },
        });
    }

    return (
            <form onSubmit={handleSubmit}>
                <Box>
                    <TextField
                        name="email"
                        placeholder="email"
                        onChange={emailChange}
                    />
                </Box>
                <Box>
                    <TextField
                        name="password"
                        placeholder="password"
                        onChange={passwordChange}
                    />
                </Box>
                <Box>
                    <Button
                        type="submit"
                    >
                        Login
                </Button>
                </Box>
            </form>
    );
}

export default Login;