import { createMuiTheme, colors } from '@material-ui/core';
import typography from './typography';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      lsm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      //main: colors.indigo[500]
      main: '#123274'
    },
    secondary: {
      //main: colors.indigo[500]
      main: '#123274'
    },
    text: {
      primary: '#000000', //colors.blueGrey[900],
      secondary: '#B3B3B3', //colors.blueGrey[600]
    }
  },
  typography
});

export default theme;
