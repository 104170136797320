import React from 'react';

import PropTypes from 'prop-types';

import {
  Checkbox,
  TableCell,
  TableRow,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const AlltiresCardItem = ({ className, categoryKey, subCategoryKey, tyreKey,tyresAndCategories, handleTyreChange, ...rest }) => {
  return (
    <TableRow
      hover
      //selected={selectedTyreIds.indexOf(tyre.id) !== -1}
    >
      <TableCell align="left">
        <Checkbox 
          checked={tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres[tyreKey].isSelected || false}
          onChange={(e) => handleTyreChange(e, subCategoryKey, categoryKey)}
          name={tyreKey}/>              
      </TableCell>  
      <TableCell>
        {tyreKey}
      </TableCell>
      <TableCell>
        {categoryKey}
      </TableCell>
      <TableCell>
        {subCategoryKey}
      </TableCell>
    </TableRow>
  );
};

AlltiresCardItem.propTypes = {
  className: PropTypes.string,
  categoryKey: PropTypes.string,
  subCategoryKey: PropTypes.string,
  tyreKey: PropTypes.string,
  tyresAndCategories: PropTypes.object.isRequired

};

export default AlltiresCardItem;
