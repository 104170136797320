import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import SubCategoriesCardItem from './SubCategoriesCardItem';

const useStyles = makeStyles(({
  root: {}
}));

const SubCategoriesCard = ({ tyresAndCategories, handleSubCategoryChange }) => {
  return (
    <Box mt={3}>
      <Card>
        <CardHeader
          title="Subcategories"
        />
        <Divider />
        <CardContent>
          {Object.keys(tyresAndCategories).map(categoryKey => {
            return (
              <SubCategoriesCardItem
                key={categoryKey}
                categoryKey={categoryKey}
                tyresAndCategories={tyresAndCategories}
                handleSubCategoryChange={handleSubCategoryChange} />
            );
          })
          }
        </CardContent>
      </Card>
    </Box>
  );
};

SubCategoriesCard.propTypes = {
  tyresAndCategories: PropTypes.object.isRequired
};

export default SubCategoriesCard;
