import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Pool from "../../../UserPool";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(({
  root: {}
}));

const Password = ({ className }) => {
  const classes = useStyles();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  }

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  }

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            resolve({ user, ...session });
          }
        })
      }
      else {
        reject();
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    getSession().then(({ user }) => {
      user.changePassword(currentPassword, newPassword, (err, result) => {
        if (err) {
          enqueueSnackbar(err.message, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          });

        } else {
          enqueueSnackbar("Password is changed", {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          });
        }
      })
    })

  }

  return (
    <form
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >
      <Card>
        <CardHeader
          subheader="Update password"
          title="Password"
        />
        <Divider />
        <CardContent>
          <TextField
            required
            fullWidth
            label="Current Password"
            margin="normal"
            name="Current password"
            onChange={handleCurrentPasswordChange}
            type="password"
            value={currentPassword}
            variant="outlined"
          />
          <TextField
            required
            fullWidth
            label="New Password"
            margin="normal"
            name="New Password"
            onChange={handleNewPasswordChange}
            type="password"
            value={newPassword}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
