import React from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ProfileDetails from './ProfileDetails';
import { createUser } from 'src/server/UserAPI';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const createUserAccount = (values, actions) => {
    const { name, email, phone_number } = values

    createUser(name, email, phone_number)
    .then(() => {
      enqueueSnackbar('User '+ name +' Has Been Successfully Created', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal:  'center' } })
      actions.setSubmitting(false)
      actions.resetForm({})
    })
    .catch((error) => {
      enqueueSnackbar(error, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal:  'center' } })
      actions.setSubmitting(false)
    })
  }

  return (
    <Page
      className={classes.root}
      title="Account"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <ProfileDetails createUser={createUserAccount} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
