import fetchClient from "./fetchClient";

function getUsers(paginationLimit, email_id, paginationToken) {
    const requestBody = {
        limit: paginationLimit,
        email_id: email_id,
        token: paginationToken
    };
    return fetchClient('users/all', {body: requestBody}, true)
}

function createUser(name, email_id, phone_number) {
    const requestBody = {
        name: name,
        email_id: email_id,
        phone_number: phone_number
    };
    return fetchClient('users/createUser', {body: requestBody}, true)
}

function deleteUser(user_name) {
    const requestBody = {
        user_name: user_name
    };
    return fetchClient('users/deleteUser', {body: requestBody}, true)
}

function resetUserPassword(user_name, email_id) {
    const requestBody = {
        user_name: user_name,
        email_id: email_id,
    };
    return fetchClient('user/resetPassword', { body: requestBody }, true)
}

function updateUserAttributes(userName, tyres) {
    const requestBody = {
        user_name: userName,
        tire: JSON.stringify(tyres)
    };
    return fetchClient('users/UpdateUserAttributes', { body: requestBody }, true)
}

function getUser(userName) {
    const requestBody = {
        user_name: userName
    };
    return fetchClient('users/getUser', { body: requestBody }, true)
}

function getTyres() {
    return fetchClient('tires/all', {}, true)
}

export { getUsers, createUser, deleteUser, resetUserPassword, updateUserAttributes, getUser, getTyres }
