import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  makeStyles,
  TableFooter,
  Select,
  MenuItem
} from '@material-ui/core';
// import getInitials from 'src/utils/getInitials';

import { Link } from 'react-router-dom';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {},
  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  }
}));

const Results = ({ className, customers, paginationLimit, handlePaginationLimitChange, tablePaginationActions, handleDeleteUserClick, handleResetUserClick, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={768}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Registration date
                </TableCell>
                <TableCell align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer, index) => (
                <TableRow
                  hover
                  key={index}
                // selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                >
                  <TableCell>
                    {customer.Attributes[0].Value}
                  </TableCell>
                  <TableCell>
                    {customer.Attributes[1].Value}
                  </TableCell>
                  <TableCell>
                    {moment(customer.UserCreateDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Assign tyre" arrow>
                      <Link className={classes.link} to={`assignTyre/${customer.Username}`}>
                        <IconButton color="inherit">
                          <SettingsOutlinedIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Resend password" arrow>
                      <IconButton color="inherit" onClick={() => handleResetUserClick(customer)}>
                        <RotateLeftIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete user" arrow>
                      <IconButton color="inherit" onClick={() => handleDeleteUserClick(customer)}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TableFooter style={{ display: 'flex' }}>
            <Box py={2} px={1.5} width='100%' display='flex' flexDirection='row' justifyContent='flex-end'>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <Typography style={{ fontSize: 14, marginRight: 10 }}>Rows per page:</Typography>
                <Select disableUnderline value={paginationLimit} onChange={handlePaginationLimitChange}>
                  {[5, 10, 15].map((limit, index) => (
                    <MenuItem key={index} value={limit}>{limit}</MenuItem>
                  ))}
                </Select>
              </Box>
              {tablePaginationActions()}
            </Box>
          </TableFooter>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
