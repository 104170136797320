import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {},
  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  }
}));

const Results = ({ className, tyres, tyresAndCategories }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getCategoryName = (id) => {
    const category = Object.keys(tyresAndCategories).find(categoryKey => tyresAndCategories[categoryKey].id == id)
    return (category) ? category : " "
  }

  const renderTableRows = () => {
    return (
      <TableBody>
        {Object.keys(tyres).slice(page * limit, page * limit + limit).map((tyreKey) => (
          <TableRow
            hover
            key={tyreKey}
          // selected={selectedCustomerIds.indexOf(tyre.id) !== -1}
          >
            <TableCell>
            {tyres[tyreKey].name}
            </TableCell>
            <TableCell>
              {getCategoryName(tyres[tyreKey].purpose_id)}
            </TableCell>
            <TableCell>
              {tyreKey}
            </TableCell>
            <TableCell >
              {tyres[tyreKey].ogmo_id ?
                <Tooltip title="Show model" arrow>
                  <a href={`https://v.ogmo.xyz/?id=${tyres[tyreKey].ogmo_id}`} target="_blank">
                    <LinkOutlinedIcon />
                  </a>
                </Tooltip> : <div>3d model is not added</div>}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <PerfectScrollbar>
        <Box minWidth={768}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Category
                </TableCell>
                <TableCell>
                  Product ID
                </TableCell>
                <TableCell>
                  3D Model
                </TableCell>
              </TableRow>
            </TableHead>
              {Object.keys(tyres).lengt === 0 ? <TableBody><TableRow><TableCell colSpan={4} align='center'>No records to display</TableCell></TableRow></TableBody> : renderTableRows()}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={Object.keys(tyres).length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  tyres: PropTypes.object.isRequired,
  tyresAndCategories: PropTypes.object.isRequired
};

export default Results;
