import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import AlltiresCardItem from './AlltiresCardItem';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const AlltiresCard = ({ className, tyresCount, tyresAndCategories, handleTyreChange, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const classes = useStyles();
  return (
    <Box mt={3}>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardHeader
          action={
            <Toolbar/>
          }
          title="All tires"
        />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>  
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Category
                  </TableCell>
                  <TableCell>
                    Subcategory
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(tyresAndCategories).map(categoryKey => {
                  return Object.keys(tyresAndCategories[categoryKey].machineries).map(subCategoryKey => {
                    return Object.keys(tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres).map(tyreKey => {
                      return (<AlltiresCardItem
                        key={tyreKey}
                        categoryKey={categoryKey}
                        subCategoryKey={subCategoryKey}
                        tyreKey={tyreKey}
                        tyresAndCategories={tyresAndCategories}
                        handleTyreChange={handleTyreChange} />
                      )
                    })
                  })
                })
                }
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </Box>
  );
};

AlltiresCard.propTypes = {
  className: PropTypes.string,
  tyresAndCategories: PropTypes.object.isRequired,
  tyresCount: PropTypes.number.isRequired
};

export default AlltiresCard;
