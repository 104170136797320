import React, { useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import UserPool from '../../UserPool';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import AuthContext from './../../contexts/AuthContext';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Please Add A Valid E-mail Address').max(255).required('Please Add The E-mail Address'),
              password: Yup.string().max(255).required('Please Add The Password')
            })}
            onSubmit={(values) => {
              const user = new CognitoUser({
                Username: values.email,
                Pool: UserPool,
              });

              const authDetails = new AuthenticationDetails({
                Username: values.email,
                Password: values.password,
              });

              user.authenticateUser(authDetails, {
                onSuccess: data => {
                  const idToken = data.getIdToken();
                  if(idToken.payload['cognito:groups']){
                    auth.toggleIsAuthenticated();
                    navigate('/app/customers', { replace: true });
                  }
                  else{
                    enqueueSnackbar('You are not authorized to log in to the admin portal. If you think this is an error, please contact the admin',{
                       variant: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'center' } 
                      })
                  }
                },

                onFailure: error => {
                  console.log("onFailure :", error);
                  // When login firstly as a new user it is required to change the password, fail the authentication, need to confirm user from userpool manually
                  // if (error.code === "UserNotConfirmedException"){
                  //   navigate('/app/settings', { replace: true });
                  if (error.name == "PasswordResetRequiredException") {
                    enqueueSnackbar("Required To Renew The Password", {
                      variant: "info",
                      anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    });
                  } else
                    enqueueSnackbar(error.message, {
                      variant: "error",
                      anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    });
                  // }
                },

                newPasswordRequired: data => {
                  console.log("newPasswordRequired", data);
                  enqueueSnackbar("Required To Renew The Password", {
                    variant: "info",
                    anchorOrigin: { vertical: "bottom", horizontal: "center" },
                  });
                }
              })
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    // disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
